/**
 * Please note:
 *
 * Translations are provided as an illustration only and are
 * not guaranteed to be accurate or error free.
 *
 * They are designed to show developers where to store their
 * chosen phrase or spelling variant in the target language.
 */

export const AG_GRID_LOCALE_DE = {
  // Set Filter
  selectAll: '(Alles auswählen)',
  selectAllSearchResults: '(Alle Suchergebnisse auswählen)',
  addCurrentSelectionToFilter: 'Aktuelle Auswahl zum Filter hinzufügen',
  searchOoo: 'Suchen...',
  blanks: '(Leer)',
  noMatches: 'Keine Übereinstimmungen',

  // Number Filter & Text Filter
  filterOoo: 'Filter...',
  equals: 'Gleich',
  notEqual: 'Ungleich',
  blank: 'Leer',
  notBlank: 'Nicht leer',
  empty: 'Wählen Sie eine',

  // Number Filter
  lessThan: 'Weniger als',
  greaterThan: 'Größer als',
  lessThanOrEqual: 'Weniger als oder gleich',
  greaterThanOrEqual: 'Größer als oder gleich',
  inRange: 'Zwischen',
  inRangeStart: 'Von',
  inRangeEnd: 'Bis',

  // Text Filter
  contains: 'Enthält',
  notContains: 'Enthält nicht',
  startsWith: 'Beginnt mit',
  endsWith: 'Endet mit',

  // Date Filter
  dateFormatOoo: 'yyyy-mm-dd',
  before: 'Bis (einschl.)',
  after: 'Ab (einschl.)',
  dateInRange: 'Von … bis … (einschl.)',

  // Filter Conditions
  andCondition: 'UND',
  orCondition: 'ODER',

  // Filter Buttons
  applyFilter: 'Anwenden',
  resetFilter: 'Zurücksetzen',
  clearFilter: 'Löschen',
  cancelFilter: 'Abbrechen',

  // Filter Titles
  textFilter: 'Textfilter',
  numberFilter: 'Zahlenfilter',
  dateFilter: 'Datumsfilter',
  setFilter: 'Satzfilter',

  // Group Column Filter
  groupFilterSelect: 'Feld auswählen:',

  // Advanced Filter
  advancedFilterContains: 'enthält',
  advancedFilterNotContains: 'enthält nicht',
  advancedFilterTextEquals: 'gleich',
  advancedFilterTextNotEqual: 'ungleich',
  advancedFilterStartsWith: 'beginnt mit',
  advancedFilterEndsWith: 'endet mit',
  advancedFilterBlank: 'ist leer',
  advancedFilterNotBlank: 'ist nicht leer',
  advancedFilterEquals: '=',
  advancedFilterNotEqual: '!=',
  advancedFilterGreaterThan: '>',
  advancedFilterGreaterThanOrEqual: '>=',
  advancedFilterLessThan: '<',
  advancedFilterLessThanOrEqual: '<=',
  advancedFilterTrue: 'ist wahr',
  advancedFilterFalse: 'ist falsch',
  advancedFilterAnd: 'UND',
  advancedFilterOr: 'ODER',
  advancedFilterApply: 'Anwenden',
  advancedFilterBuilder: 'Ersteller',
  advancedFilterValidationMissingColumn: 'Spalte fehlt',
  advancedFilterValidationMissingOption: 'Option fehlt',
  advancedFilterValidationMissingValue: 'Wert fehlt',
  advancedFilterValidationInvalidColumn: 'Spalte nicht gefunden',
  advancedFilterValidationInvalidOption: 'Option nicht gefunden',
  advancedFilterValidationMissingQuote: 'Wert fehlt ein Schlusszeichen',
  advancedFilterValidationNotANumber: 'Wert ist keine Nummer',
  advancedFilterValidationInvalidDate: 'Wert ist kein gültiges Datum',
  advancedFilterValidationMissingCondition: 'Bedingung fehlt',
  advancedFilterValidationJoinOperatorMismatch: 'Verknüpfungsoperatoren innerhalb einer Bedingung müssen gleich sein',
  advancedFilterValidationInvalidJoinOperator: 'Verknüpfungsoperator nicht gefunden',
  advancedFilterValidationMissingEndBracket: 'Endklammer fehlt',
  advancedFilterValidationExtraEndBracket: 'Zu viele Endklammern',
  advancedFilterValidationMessage: 'Ausdruck enthält einen Fehler. ${variable} - ${variable}.',
  advancedFilterValidationMessageAtEnd: 'Ausdruck enthält einen Fehler. ${variable} am Ende des Ausdrucks.',
  advancedFilterBuilderTitle: 'Erweiterter Filter',
  advancedFilterBuilderApply: 'Anwenden',
  advancedFilterBuilderCancel: 'Abbrechen',
  advancedFilterBuilderAddButtonTooltip: 'Filter oder Gruppe hinzufügen',
  advancedFilterBuilderRemoveButtonTooltip: 'Entfernen',
  advancedFilterBuilderMoveUpButtonTooltip: 'Nach oben bewegen',
  advancedFilterBuilderMoveDownButtonTooltip: 'Nach unten bewegen',
  advancedFilterBuilderAddJoin: 'Gruppe hinzufügen',
  advancedFilterBuilderAddCondition: 'Filter hinzufügen',
  advancedFilterBuilderSelectColumn: 'Eine Spalte auswählen',
  advancedFilterBuilderSelectOption: 'Eine Option auswählen',
  advancedFilterBuilderEnterValue: 'Einen Wert eingeben...',
  advancedFilterBuilderValidationAlreadyApplied: 'Aktueller Filter bereits angewendet.',
  advancedFilterBuilderValidationIncomplete: 'Nicht alle Bedingungen sind vollständig.',
  advancedFilterBuilderValidationSelectColumn: 'Eine Spalte muss ausgewählt werden.',
  advancedFilterBuilderValidationSelectOption: 'Eine Option muss ausgewählt werden.',
  advancedFilterBuilderValidationEnterValue: 'Ein Wert muss eingegeben werden.',

  // Side Bar
  columns: 'Spalten',
  filters: 'Filter',

  // columns tool panel
  pivotMode: 'Pivot-Modus',
  groups: 'Zeilengruppen',
  rowGroupColumnsEmptyMessage: 'Ziehen Sie hierher, um Zeilengruppen festzulegen',
  values: 'Werte',
  valueColumnsEmptyMessage: 'Ziehen Sie hierher, um zu aggregieren',
  pivots: 'Spaltenbezeichnungen',
  pivotColumnsEmptyMessage: 'Ziehen Sie hierher, um Spaltenbezeichnungen festzulegen',

  // Header of the Default Group Column
  group: 'Gruppe',

  // Row Drag
  rowDragRow: 'Reihe',
  rowDragRows: 'Reihen',

  // Other
  loadingOoo: 'Wird geladen...',
  loadingError: 'FEHLER',
  noRowsToShow: 'Keine Zeilen zum Anzeigen',
  enabled: 'Aktiviert',

  // Menu
  pinColumn: 'Spalte anheften',
  pinLeft: 'Links anheften',
  pinRight: 'Rechts anheften',
  noPin: 'Nicht anheften',
  valueAggregation: 'Wertaggregation',
  noAggregation: 'Keine',
  autosizeThiscolumn: 'Diese Spalte automatisch anpassen',
  autosizeAllColumns: 'Alle Spalten automatisch anpassen',
  groupBy: 'Gruppieren nach',
  ungroupBy: 'Gruppierung aufheben nach',
  ungroupAll: 'Alle Gruppierungen aufheben',
  addToValues: 'Füge ${variable} zu Werten hinzu',
  removeFromValues: 'Entferne ${variable} aus Werten',
  addToLabels: 'Füge ${variable} zu Labels hinzu',
  removeFromLabels: 'Entferne ${variable} aus Labels',
  resetColumns: 'Spalten zurücksetzen',
  expandAll: 'Alle Zeilengruppen erweitern',
  collapseAll: 'Alle Zeilengruppen schließen',
  copy: 'Kopieren',
  ctrlC: 'Strg+C',
  ctrlX: 'Strg+X',
  copyWithHeaders: 'Mit Kopfzeilen kopieren',
  copyWithGroupHeaders: 'Mit Gruppenkopfzeilen kopieren',
  cut: 'Ausschneiden',
  paste: 'Einfügen',
  ctrlV: 'Strg+V',
  export: 'Exportieren',
  csvExport: 'CSV-Export',
  excelExport: 'Excel-Export',
  columnFilter: 'Spaltenfilter',
  columnChooser: 'Spalten auswählen',
  sortAscending: 'Aufsteigend sortieren',
  sortDescending: 'Absteigend sortieren',
  sortUnSort: 'Sortierung aufheben',

  // Enterprise Menu Aggregation and Status Bar
  sum: 'Summe',
  first: 'Erste',
  last: 'Letzte',
  min: 'Min',
  max: 'Max',
  none: 'Keine',
  count: 'Anzahl',
  avg: 'Durchschnitt',
  filteredRows: 'Gefiltert',
  selectedRows: 'Ausgewählt',
  totalRows: 'Gesamtanzahl Zeilen',
  totalAndFilteredRows: 'Zeilen',
  more: 'Mehr',
  to: 'bis',
  of: 'von',
  page: 'Seite',
  pageLastRowUnknown: '?',
  nextPage: 'Nächste Seite',
  lastPage: 'Letzte Seite',
  firstPage: 'Erste Seite',
  previousPage: 'Vorherige Seite',
  pageSizeSelectorLabel: 'Seitengröße:',
  footerTotal: 'Gesamt',

  // Pivoting
  pivotColumnGroupTotals: 'Gesamt',

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: 'Pivot-Diagramm & Pivot-Modus',
  pivotChart: 'Pivot-Diagramm',
  chartRange: 'Diagrammbereich',
  columnChart: 'Säule',
  groupedColumn: 'Gruppiert',
  stackedColumn: 'Gestapelt',
  normalizedColumn: '100% Gestapelt',
  barChart: 'Balken',
  groupedBar: 'Gruppiert',
  stackedBar: 'Gestapelt',
  normalizedBar: '100% Gestapelt',
  pieChart: 'Kreisdiagramm',
  pie: 'Kreis',
  donut: 'Donut',
  line: 'Linie',
  xyChart: 'X Y (Streudiagramm)',
  scatter: 'Streu',
  bubble: 'Blase',
  areaChart: 'Fläche',
  area: 'Fläche',
  stackedArea: 'Gestapelt',
  normalizedArea: '100% Gestapelt',
  histogramChart: 'Histogramm',
  polarChart: 'Polar',
  radarLine: 'Radarlinie',
  radarArea: 'Radarfläche',
  nightingale: 'Nachtigall',
  radialColumn: 'Radiale Säule',
  radialBar: 'Radialer Balken',
  statisticalChart: 'Statistisch',
  boxPlot: 'Box-Plot',
  rangeBar: 'Bereichsbalken',
  rangeArea: 'Bereichsfläche',
  hierarchicalChart: 'Hierarchisch',
  treemap: 'Baumkarte',
  sunburst: 'Sonnenstrahl',
  specializedChart: 'Spezialisiert',
  waterfall: 'Wasserfall',
  heatmap: 'Heatmap',
  combinationChart: 'Kombination',
  columnLineCombo: 'Säule & Linie',
  AreaColumnCombo: 'Fläche & Säule',

  // Charts
  pivotChartTitle: 'Pivot-Diagramm',
  rangeChartTitle: 'Bereichsdiagramm',
  settings: 'Diagramm',
  data: 'Einrichten',
  format: 'Anpassen',
  categories: 'Kategorien',
  defaultCategory: '(Keine)',
  series: 'Reihen',
  switchCategorySeries: 'Kategorie / Serie wechseln',
  categoryValues: 'Kategorie Werte',
  seriesLabels: 'Serienbeschriftungen',
  aggregate: 'Aggregieren',
  xyValues: 'X Y Werte',
  paired: 'Gekoppelter Modus',
  axis: 'Achse',
  xAxis: 'Horizontale Achse',
  yAxis: 'Vertikale Achse',
  polarAxis: 'Polare Achse',
  radiusAxis: 'Radius-Achse',
  navigator: 'Navigator',
  zoom: 'Zoom',
  animation: 'Animation',
  crosshair: 'Fadenkreuz',
  color: 'Farbe',
  thickness: 'Dicke',
  preferredLength: 'Bevorzugte Länge',
  xType: 'X-Typ',
  axisType: 'Achsentyp',
  automatic: 'Automatisch',
  category: 'Kategorie',
  number: 'Zahl',
  time: 'Zeit',
  timeFormat: 'Zeit Format',
  autoRotate: 'Automatisch Rotieren',
  labelRotation: 'Drehung',
  circle: 'Kreis',
  polygon: 'Polygon',
  square: 'Quadrat',
  cross: 'Kreuz',
  diamond: 'Diamant',
  plus: 'Plus',
  triangle: 'Dreieck',
  heart: 'Herz',
  orientation: 'Ausrichtung',
  fixed: 'Fest',
  parallel: 'Parallel',
  perpendicular: 'Senkrecht',
  radiusAxisPosition: 'Position',
  ticks: 'Striche',
  gridLines: 'Gitternetzlinien',
  width: 'Breite',
  height: 'Höhe',
  length: 'Länge',
  padding: 'Abstand',
  spacing: 'Abstand',
  chartStyle: 'Diagrammstil',
  title: 'Titel',
  chartTitles: 'Titel',
  chartTitle: 'Diagrammtitel',
  chartSubtitle: 'Untertitel',
  horizontalAxisTitle: 'Titel der horizontalen Achse',
  verticalAxisTitle: 'Titel der vertikalen Achse',
  polarAxisTitle: 'Titel der Polarakse',
  titlePlaceholder: 'Diagrammtitel',
  background: 'Hintergrund',
  font: 'Schriftart',
  weight: 'Gewicht',
  top: 'Oben',
  right: 'Rechts',
  bottom: 'Unten',
  left: 'Links',
  labels: 'Beschriftungen',
  calloutLabels: 'Beschriftungen',
  sectorLabels: 'Sektorbeschriftungen',
  positionRatio: 'Positionsverhältnis',
  size: 'Größe',
  shape: 'Form',
  minSize: 'Mindestgröße',
  maxSize: 'Maximalgröße',
  legend: 'Legende',
  position: 'Position',
  markerSize: 'Markierungsgröße',
  markerStroke: 'Markierungsstrich',
  markerPadding: 'Markierungsabstand',
  itemSpacing: 'Elementabstand',
  itemPaddingX: 'Elementabstand X',
  itemPaddingY: 'Elementabstand Y',
  layoutHorizontalSpacing: 'Horizontaler Abstand',
  layoutVerticalSpacing: 'Vertikaler Abstand',
  strokeWidth: 'Strichbreite',
  offset: 'Versatz',
  offsets: 'Versätze',
  tooltips: 'Tooltips',
  callout: 'Beschriftung',
  markers: 'Markierungen',
  shadow: 'Schatten',
  blur: 'Unschärfe',
  xOffset: 'X-Versatz',
  yOffset: 'Y-Versatz',
  lineWidth: 'Linienbreite',
  lineDash: 'Linienstrich',
  lineDashOffset: 'Strichversatz',
  scrollingZoom: 'Scrollen',
  scrollingStep: 'Scrollschritt',
  selectingZoom: 'Auswählen',
  durationMillis: 'Dauer (ms)',
  crosshairLabel: 'Beschriftung',
  crosshairSnap: 'An Knoten schnappen',
  normal: 'Normal',
  bold: 'Fett',
  italic: 'Kursiv',
  boldItalic: 'Fett Kursiv',
  predefined: 'Vordefiniert',
  fillOpacity: 'Füllopazität',
  strokeColor: 'Linienfarbe',
  strokeOpacity: 'Linienopazität',
  miniChart: 'Mini-Diagramm',
  histogramBinCount: 'Anzahl der Bins',
  connectorLine: 'Verbindungslinie',
  seriesItems: 'Serienartikel',
  seriesItemType: 'Artikeltyp',
  seriesItemPositive: 'Positiv',
  seriesItemNegative: 'Negativ',
  seriesItemLabels: 'Artikel Beschriftungen',
  columnGroup: 'Spalte',
  barGroup: 'Balken',
  pieGroup: 'Kreis',
  lineGroup: 'Linie',
  scatterGroup: 'X Y (Streudiagramm)',
  areaGroup: 'Bereich',
  polarGroup: 'Polar',
  statisticalGroup: 'Statistisch',
  hierarchicalGroup: 'Hierarchisch',
  specializedGroup: 'Spezialisiert',
  combinationGroup: 'Kombination',
  groupedColumnTooltip: 'Gruppiert',
  stackedColumnTooltip: 'Gestapelt',
  normalizedColumnTooltip: '100% Gestapelt',
  groupedBarTooltip: 'Gruppiert',
  stackedBarTooltip: 'Gestapelt',
  normalizedBarTooltip: '100% Gestapelt',
  pieTooltip: 'Kreis',
  donutTooltip: 'Donut',
  lineTooltip: 'Linie',
  groupedAreaTooltip: 'Bereich',
  stackedAreaTooltip: 'Gestapelt',
  normalizedAreaTooltip: '100% Gestapelt',
  scatterTooltip: 'Streudiagramm',
  bubbleTooltip: 'Blase',
  histogramTooltip: 'Histogramm',
  radialColumnTooltip: 'Radiale Spalte',
  radialBarTooltip: 'Radialer Balken',
  radarLineTooltip: 'Radarlinie',
  radarAreaTooltip: 'Radarbereich',
  nightingaleTooltip: 'Nachtigall',
  rangeBarTooltip: 'Bereichsbalken',
  rangeAreaTooltip: 'Bereichsbereich',
  boxPlotTooltip: 'Boxplot',
  treemapTooltip: 'Baumkarte',
  sunburstTooltip: 'Sunburst',
  waterfallTooltip: 'Wasserfall',
  heatmapTooltip: 'Heatmap',
  columnLineComboTooltip: 'Spalte & Linie',
  areaColumnComboTooltip: 'Bereich & Spalte',
  customComboTooltip: 'Benutzerdefinierte Kombination',
  innerRadius: 'Innerer Radius',
  startAngle: 'Startwinkel',
  endAngle: 'Endwinkel',
  reverseDirection: 'Richtung umkehren',
  groupPadding: 'Gruppenabstand',
  seriesPadding: 'Serienabstand',
  tile: 'Kachel',
  whisker: 'Schnurrbart',
  cap: 'Kappe',
  capLengthRatio: 'Längenverhältnis',
  labelPlacement: 'Platzierung',
  inside: 'Innen',
  outside: 'Außen',
  noDataToChart: 'Keine Daten zum Chart vorhanden.',
  pivotChartRequiresPivotMode: 'Pivot-Diagramm erfordert aktivierten Pivot-Modus.',
  chartSettingsToolbarTooltip: 'Menü',
  chartLinkToolbarTooltip: 'Mit Gitter verbunden',
  chartUnlinkToolbarTooltip: 'Vom Gitter getrennt',
  chartDownloadToolbarTooltip: 'Diagramm herunterladen',
  chartMenuToolbarTooltip: 'Menü',
  chartEdit: 'Diagramm bearbeiten',
  chartAdvancedSettings: 'Erweiterte Einstellungen',
  chartLink: 'Mit Gitter verknüpfen',
  chartUnlink: 'Vom Gitter trennen',
  chartDownload: 'Diagramm herunterladen',
  histogramFrequency: 'Häufigkeit',
  seriesChartType: 'Serien-Diagrammtyp',
  seriesType: 'Serientyp',
  secondaryAxis: 'Sekundäre Achse',
  seriesAdd: 'Eine Serie hinzufügen',
  categoryAdd: 'Eine Kategorie hinzufügen',
  bar: 'Balken',
  column: 'Spalte',
  histogram: 'Histogramm',
  advancedSettings: 'Erweiterte Einstellungen',
  direction: 'Richtung',
  horizontal: 'Horizontal',
  vertical: 'Vertikal',
  seriesGroupType: 'Gruppentyp',
  groupedSeriesGroupType: 'Gruppiert',
  stackedSeriesGroupType: 'Gestapelt',
  normalizedSeriesGroupType: '100% Gestapelt',
  legendEnabled: 'Aktiviert',
  invalidColor: 'Farbwert ist ungültig',
  groupedColumnFull: 'Gruppierte Spalte',
  stackedColumnFull: 'Gestapelte Spalte',
  normalizedColumnFull: '100% Gestapelte Spalte',
  groupedBarFull: 'Gruppierter Balken',
  stackedBarFull: 'Gestapelter Balken',
  normalizedBarFull: '100% Gestapelter Balken',
  stackedAreaFull: 'Gestapelter Bereich',
  normalizedAreaFull: '100% Gestapelter Bereich',
  customCombo: 'Benutzerdefinierte Kombination',

  // ARIA
  ariaAdvancedFilterBuilderItem: '${variable}. Ebene ${variable}. Drücken Sie ENTER, um zu bearbeiten.',
  ariaAdvancedFilterBuilderItemValidation:
    '${variable}. Ebene ${variable}. ${variable} Drücken Sie ENTER, um zu bearbeiten.',
  ariaAdvancedFilterBuilderList: 'Erweiterte Filter-Builder-Liste',
  ariaAdvancedFilterBuilderFilterItem: 'Filterbedingung',
  ariaAdvancedFilterBuilderGroupItem: 'Filtergruppe',
  ariaAdvancedFilterBuilderColumn: 'Spalte',
  ariaAdvancedFilterBuilderOption: 'Option',
  ariaAdvancedFilterBuilderValueP: 'Wert',
  ariaAdvancedFilterBuilderJoinOperator: 'Verknüpfungsoperator',
  ariaAdvancedFilterInput: 'Erweiterter Filtereingang',
  ariaChecked: 'geprüft',
  ariaColumn: 'Spalte',
  ariaColumnGroup: 'Spaltengruppe',
  ariaColumnFiltered: 'Spalte gefiltert',
  ariaColumnSelectAll: 'Alle Spalten auswählen umschalten',
  ariaDateFilterInput: 'Datum-Filtereingang',
  ariaDefaultListName: 'Liste',
  ariaFilterColumnsInput: 'Filterspalteneingang',
  ariaFilterFromValue: 'Filter vom Wert',
  ariaFilterInput: 'Filtereingang',
  ariaFilterList: 'Filterliste',
  ariaFilterToValue: 'Filter zum Wert',
  ariaFilterValue: 'Filterwert',
  ariaFilterMenuOpen: 'Filtermenü öffnen',
  ariaFilteringOperator: 'Filteroperator',
  ariaHidden: 'versteckt',
  ariaIndeterminate: 'unbestimmt',
  ariaInputEditor: 'Eingabeeditor',
  ariaMenuColumn: 'Drücken Sie ALT NACH UNTEN, um das Spaltenmenü zu öffnen',
  ariaFilterColumn: 'Drücken Sie STRG EINGABETASTE, um den Filter zu öffnen',
  ariaRowDeselect: 'Drücken Sie LEERTASTE, um diese Zeile abzuwaehlen',
  ariaRowSelectAll: 'Drücken Sie LEERTASTE, um die Auswahl aller Zeilen umzuschalten',
  ariaRowToggleSelection: 'Drücken Sie LEERTASTE, um die Zeilenauswahl umzuschalten',
  ariaRowSelect: 'Drücken Sie LEERTASTE, um diese Zeile auszuwählen',
  ariaRowSelectionDisabled: 'Zeilenauswahl ist für diese Zeile deaktiviert',
  ariaSearch: 'Suche',
  ariaSortableColumn: 'Drücken Sie EINGABETASTE, um zu sortieren',
  ariaToggleVisibility: 'Drücken Sie LEERTASTE, um die Sichtbarkeit umzuschalten',
  ariaToggleCellValue: 'Drücken Sie LEERTASTE, um den Zellenwert umzuschalten',
  ariaUnchecked: 'ungeprüft',
  ariaVisible: 'sichtbar',
  ariaSearchFilterValues: 'Filterwerte suchen',
  ariaPageSizeSelectorLabel: 'Seitengröße',
  ariaChartMenuClose: 'Diagramm-Bearbeitungsmenü schließen',
  ariaChartSelected: 'Ausgewählt',
  ariaSkeletonCellLoadingFailed: 'Das Laden der Zeile ist fehlgeschlagen',
  ariaSkeletonCellLoading: 'Zeilendaten werden geladen',

  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: 'Zeilengruppen',
  ariaValuesDropZonePanelLabel: 'Werte',
  ariaPivotDropZonePanelLabel: 'Spaltenbeschriftungen',
  ariaDropZoneColumnComponentDescription: 'Drücken Sie DELETE, um zu entfernen',
  ariaDropZoneColumnValueItemDescription: 'Drücken Sie ENTER, um den Aggregationstyp zu ändern',
  ariaDropZoneColumnGroupItemDescription: 'Drücken Sie ENTER, um zu sortieren',

  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: ' von ',
  ariaDropZoneColumnComponentSortAscending: 'aufsteigend',
  ariaDropZoneColumnComponentSortDescending: 'absteigend',
  ariaLabelDialog: 'Dialog',
  ariaLabelColumnMenu: 'Spaltenmenü',
  ariaLabelColumnFilter: 'Spaltenfilter',
  ariaLabelCellEditor: 'Zelleneditor',
  ariaLabelSelectField: 'Feld auswählen',

  // aria labels for rich select
  ariaLabelRichSelectField: 'Rich-Select-Feld',
  ariaLabelRichSelectToggleSelection: 'Drücken Sie die LEERTASTE, um die Auswahl umzuschalten',
  ariaLabelRichSelectDeselectAllItems: 'Drücken Sie ENTFERNEN, um alle Elemente abzuwählen',
  ariaLabelRichSelectDeleteSelection: 'Drücken Sie ENTFERNEN, um das Element abzuwählen',
  ariaLabelTooltip: 'Tooltip',
  ariaLabelContextMenu: 'Kontextmenü',
  ariaLabelSubMenu: 'Untermenü',
  ariaLabelAggregationFunction: 'Aggregationsfunktion',
  ariaLabelAdvancedFilterAutocomplete: 'Erweiterte Filter-Autovervollständigung',
  ariaLabelAdvancedFilterBuilderAddField: 'Erweiterte Filtererstellung Feld hinzufügen',
  ariaLabelAdvancedFilterBuilderColumnSelectField: 'Erweiterte Filtererstellung Spaltenauswahlfeld',
  ariaLabelAdvancedFilterBuilderOptionSelectField: 'Erweiterte Filtererstellung Optionsauswahlfeld',
  ariaLabelAdvancedFilterBuilderJoinSelectField: 'Erweiterte Filtererstellung Verknüpfungsoperatore Auswahlfeld',

  // ARIA Labels for the Side Bar
  ariaColumnPanelList: 'Spaltenliste',
  ariaFilterPanelList: 'Filterliste',

  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: '.',
  decimalSeparator: ',',

  // Data types
  true: 'Wahr',
  false: 'Falsch',
  invalidDate: 'Ungültiges Datum',
  invalidNumber: 'Ungültige Nummer',
  january: 'Januar',
  february: 'Februar',
  march: 'März',
  april: 'April',
  may: 'Mai',
  june: 'Juni',
  july: 'Juli',
  august: 'August',
  september: 'September',
  october: 'Oktober',
  november: 'November',
  december: 'Dezember',

  // Time formats
  timeFormatSlashesDDMMYYYY: 'TT/MM/JJJJ',
  timeFormatSlashesMMDDYYYY: 'MM/TT/JJJJ',
  timeFormatSlashesDDMMYY: 'TT/MM/JJ',
  timeFormatSlashesMMDDYY: 'MM/TT/JJ',
  timeFormatDotsDDMYY: 'TT.M.JJ',
  timeFormatDotsMDDYY: 'M.TT.JJ',
  timeFormatDashesYYYYMMDD: 'JJJJ-MM-TT',
  timeFormatSpacesDDMMMMYYYY: 'TT MMMM JJJJ',
  timeFormatHHMMSS: 'SS:MM:SS',
  timeFormatHHMMSSAmPm: 'SS:MM:SS VM/NM',
}
